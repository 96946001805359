import axios from '@/config/httpConfig'
// 提交待审批
export function submitApproval(args) {
    return axios.post('approval/submitApproval', args)
}
// 调回审核
export function reCall(args) {
    return axios.post('approval/reCallApproval', args)
}
// 获取审批预览
export function getApprovalPre(args) {
    return axios.post('approval/getGotoApprovalPreview', args)
}
// 获取审批类型
export function getApprovalType(args) {
    return axios.post('approval/getApprovalObjectList', args)
}
// 获取审批列表
export function getApprovalList(param){
    return axios.post('approval/getAllApprovalHistoryPendingList', param)
}
//获取审批编辑页面信息
export function approvalMessage(param){
    return axios.post('approval/getProcessInstanceWorkitem', param)
}
//获取用户列表
export function getUserList(){
    return axios.post('approval/getUserList')
}
//编辑审批
export function edictApproval(param){
    return axios.post('approval/updateApprovalProcessOperation', param)
}
//编辑拒绝审批
export function rejectEdictApproval(param){
    return axios.post('approval/getRejectedInstanceWorkitem', param)
}
// 普通审批与分配
export function commonApproval(param){
    return axios.post('approval/doApprovalProcessOperation', param)
}
// 批量审批与分配
export function batchApproval(param){
    return axios.post('approval/batchApprovalProcessOperation', param)
}
// 选取下一个审批人人员列表
export function getLookupData(param){
    return axios.post('lookup/getLookupData', param)
}
//获取审批相关列表
export function queryApprovalRelatedList(param){
    return axios.post('approval/queryApprovalRelatedList',param)
}
export function getApprovalHistoryList(param){
    return axios.post('approval/getApprovalHistoryList', param)
}
