<template>
  <!-- 开发人员：张亚欣 -->
  <!-- 新增查找字段对应记录 -->
  <div class="dialog-form" id="dialog-form">
    <div style="margin-bottom: 15px" v-if="showRecordType">
      <span>
        <!-- 选择记录类型 -->
        {{ $t("label.recordtypeselect") }}
        ：
      </span>
      <el-select
        v-model="recordType"
        filterable
        :placeholder="$t('label.select.please')"
      >
        <el-option
          v-for="item in recordOptions"
          :key="item.val"
          :label="item.key"
          :value="item.val"
        >
        </el-option>
      </el-select>
    </div>
    <base-form
        v-loading="loadingDetail"
        ref="baseForm"
        class="baseForm"
        :prefix="prefix"
        :form-attr="formAttr"
        :columnNum="columnNum"
        :oneColumnWidth="oneColumnWidth"
        :twoColumnWidth="twoColumnWidth"
        :option-list="optionList"
        @addFieldOption="addFieldOption"
        @addFile="addFile"
        @changeSelect="changeSelect"
        @control="control"
        @lookupValue="lookupValue"
        @handleAction="handleAction"
        @remoteMethod="remoteMethod"
        @newSearchableField="newSearchableField"
        :objectApi="objectApi"
      >
      </base-form>
    <div class="bottom_butts">
      <el-button size="mini" @click="cancel">
        <!-- 取 消 -->
        {{ $t("label.cancel") }}
      </el-button>
      <!--提交待审批 label.submitforapproval -->
      <el-button size="mini" @click="save('approval')" v-if="saveandapproval">
        {{ $t("label.submitforapproval") }}
      </el-button>
      <el-button size="mini" type="primary" @click="save" :loading="loading">
        <!-- 保存 label.chatter.save-->
        {{ $t("label.chatter.save") }}
      </el-button>
    </div>
    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    >
    </add-files>
    <!--  '无法提交待审批'  -->
    <el-dialog
      :title="$t('message.approval.cannotsubmit')"
      @close="closeApproval"
      :visible.sync="failedDialog"
      width="30%"
    >
      <span>{{ errInfo }}</span>
    </el-dialog>
    <!-- 提交待审批弹窗 -->
    <nextApproval
      ref="nextApprovalref"
      :saveandapproval="saveandapproval"
      @approvalSuccess="approvalSuccess"
    ></nextApproval>
  </div>
</template>

<script >
/**
 * 快速新建
 */
import INPUTTYPE from "@/config/enumCode/inputType";
import * as CommonObjApi from "./CommonObjApi.js";
import * as Approval from "./approval.js";
export default {
  components: {
    BaseForm: () => import("@/components/Form/index.vue"),
    AddFiles: () => import("@/components/AddFiles/AddFiles.vue"),
    nextApproval: () =>
      import("@/views/approval/components/approval-dialog.vue"),
  },
  data() {
    return {
      //是否为提交待审批
      isApproval: false,
      //是否有提交并保存
      saveandapproval: false,
      failedDialog: false, // 无法提交待审批弹窗状态
      errInfo: "", // 无法提交待审批错误提示
      columnNum: 2,
      oneColumnWidth: "100%",
      twoColumnWidth: "50%",
      attrIndex: -1,
      prefix: this.$route.params.prefix,
      objectApi: this.$route.params.objectApi,
      // 记录类型id
      recordType: "",
      recordOptions: [],
      showRecordType: false,
      formAttr: null,
      optionList: {},
      controlContainer: null, //依赖关系
      actionRequireds: [], // 动态布局必填字段
      actionReadonlys: [], // 动态布局只读字段
      actionShowFields: [], // 动态布局显示字段
      actionShowSections: [], // 动态布局显示部分
      currentUserInfo: {}, //当前登录用户信息
      dialogAddVisible: false,
      newObjLevel: 0, // 新建查找字段标识
      fieldProp: "",
      isZhucongfield: false,
      loading: false, // 保存按钮添加loading
      loadingDetail: false, // 表单loading
      jumpTo: "", // 地址栏中带的参数，表示要跳转的位置
      isEmpty: (rule, value, callback) => {
        if (value && value.toString().trim() === "") {
          // '请输入正确的电子邮箱地址'
          return callback(new Error(this.$i18n.t("label.empty.field")));
        } else {
          callback();
        }
      },
      isEmail: (rule, value, callback) => {
        const reg =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value && !reg.test(value)) {
          // '请输入正确的电子邮箱地址'
          return callback(
            new Error(this.$i18n.t("label_emailtocloudcc_redmessage1"))
          );
        } else {
          callback();
        }
      },
      isNumber: (rule, value, callback) => {
        if (value !== undefined && isNaN(value)) {
          // 您输入的不是有效数字
          return callback(new Error(this.$i18n.t("label.field.input.number")));
        } else {
          callback();
        }
      },
      twitter: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("twitter.com") === -1) {
            this.isTwitter = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.twittererror"))
            );
          } else {
            this.isTwitter = true;
            callback();
          }
        }
      },
      linkedin: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("linkedin.com") === -1) {
            this.isLinkedin = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.linkedinerror"))
            );
          } else {
            this.isLinkedin = true;
            callback();
          }
        }
      },
      facebook: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("facebook.com") === -1) {
            this.isFacebook = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.facebookerror"))
            );
          } else {
            this.isFacebook = true;
            callback();
          }
        }
      },
    };
  },
  created() {
    // 获取url中的参数,参数说明
    /**
     * 记录类型id:recordType
     */
    let urlParam=this.$route.query
    if(urlParam.recordtype){
      this.recordType=urlParam.recordtype
    }
    
    this.init();
  },
  mounted() {
    // 关闭加载框
    document.getElementById("lightning-loading").style.display = "none";
  },
  methods: {
    init() {
      this.getRecordType();
      this.getCurrentUser();
    },
    addFieldOption(prop, option) {
      if (this.optionList[prop]) {
        this.optionList[prop].push(option);
      } else {
        this.optionList[prop] = [option];
      }
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      this.currentUserInfo = {
        id: localStorage.getItem("userId"),
        name: localStorage.getItem("username"),
      };
    },
    // 获取记录类型
    getRecordType() {
      this.recordOptions = [];
      CommonObjApi.getRecordType({ prefix: this.prefix }).then((res) => {
        res.data &&
          res.data.recordTypeList &&
          res.data.recordTypeList.forEach((record) => {
            this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
        this.showRecordType = this.recordOptions.length === 0 ? false : true;
        // 如果url没有传值，默认选取第一个值,
        if(!this.$route.query.recordType){
          if(this.showRecordType){
            this.recordType =this.recordOptions[0].val
          }else{
            this.getFormAttr();
          }
           
        }
       
      });
    },
    // 添加文件
    addFile(field) {
      this.fieldProp = field.prop;
      this.$refs.addFile.initPage();
      this.dialogAddVisible = true;
    },
    // 添加文件
    addWorkSubmit(checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        ids.push(file.id);
      });
      this.$set(this.$refs.baseForm.formData, this.fieldProp, ids.toString());
      this.formAttr.map((attr) => {
        if (attr.prop === this.fieldProp) {
          attr.data = checkedFileList;
        }
      });
      this.dialogAddVisible = false;
    },
    // 关闭添加文件
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    // 远程搜索
    remoteMethod(params, item, formData) {
      // 查询是否有默认条件
      CommonObjApi.getLookupInfo({
        fieldId: params.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        // let lkdp = [""];
        let values = [''];

        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          for (let reltaion in res.data.filterFieldIds) {
            let attr = this.formAttr.find((attrItem) => {
              return (
                attrItem.fieldId === res.data.filterFieldIds[reltaion].fieldid
              );
            });
            if (
              attr !== undefined &&
              formData[attr.prop] !== null &&
              Array.isArray(formData[attr.prop])
            ) {
              values.push(formData[attr.prop].join(";"));
            } else if (attr !== undefined && formData[attr.prop] !== null) {
              values.push(formData[attr.prop]);
            }
          }
        }
        params.lkdp = JSON.stringify(values);
        CommonObjApi.queryMoreDataByName(params).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.$set(this.optionList, item.prop, options);
        });
      });
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 获取对象api后进行页面跳转
      CommonObjApi.getLookupInfo({
        fieldId: item.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        let objectApi = res.data.objectApi;
        window.open(
          `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
        );
      });
    },
    // 获取表单展示信息
    getFormAttr() {
      this.loadingDetail = true;
      this.formAttr = [];
      this.optionList = {};

      let params = {
        id: "",
        objectApi: this.objectApi,
        operation: "NEW",
        recordId: "",
        fieldId: "",
        recordtype: this.recordType?this.recordType:''
      };
      // if (this.recordType !== "") {
      //   params.recordtype = this.recordType;
      // }

      CommonObjApi.getDetail(params)
        .then((res) => {
          //是否展示提交待审批按钮
          this.saveandapproval =
            res.data.saveandapproval && res.data.saveandapproval === "true"
              ? true
              : false;
          res.data.dataList.forEach((group) => {
            if (group.showWhenEdit) {
              this.attrIndex = -1;
            }

            let colNum = group.colNum;
            if (group.showWhenEdit) {
              this.formAttr.push({
                width: "100%",
                colNum: 1,
                type: "groupTitle",
                title: group.title,
                selectionId: group.selectionId,
                groupShow: true,
                hidden: false,
              });
            }
            // 非内嵌页面
            if (group.data) {
              group.data.length > 0 &&
                group.data.forEach((line) => {
                  if (
                    line.left && !(Array.isArray(line.left) && line.left.length === 0) &&
                    line.left.fieldType !== "blank"
                  ) {
                    let attr = this.formAttr.find((item) => {
                      return item.fieldId === line.left.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group,
                        colNum,
                        line.left,
                        undefined,
                        "left"
                      );
                    }
                  } else {
                    if (line.left.fieldType == "blank") {
                      this.attrIndex += group.colNum === 1 ? 0 : 1;
                      this.formAttr.push({
                        width: "50%",
                        colNum: 2,
                        attrIndex: this.attrIndex,
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                      });
                    }
                  }
                  if (Number(colNum) !== 1) {
                    if (
                      !(Array.isArray(line.right) && line.right.length === 0) &&
                      line.right.fieldType !== "blank"
                    ) {
                      let attr = this.formAttr.find((item) => {
                        return item.fieldId === line.right.fieldId;
                      });
                      if (attr === undefined) {
                        this.addFormAttr(
                          group,
                          colNum,
                          line.right,
                          undefined,
                          "right"
                        );
                      }
                    } else {
                      this.attrIndex += group.colNum === 1 ? 0 : 1;
                      this.formAttr.push({
                        width: "50%",
                        colNum: 2,
                        attrIndex: this.attrIndex,
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                      });
                    }
                  }
                });
            } else {
              // 内嵌页面
            }
          });

          this.echoParams(); // 将URL中传入的其他参数回显到表单中
         

          this.showBaseForm = true;
          this.$nextTick(() => {
            // 强制刷新表单
            this.$refs.baseForm && this.$refs.baseForm.filterFormData();

            // 查找带值，绑定事件
            if (
              res.data.lookupValueExpression !== undefined &&
              res.data.lookupValueExpression !== "" &&
              res.data.lookupValueExpression !== null
            ) {
              this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
            }
            // 依赖关系，绑定事件
            this.controlContainer = JSON.parse(res.data.controlContainer);
            this.setControlContainer();
            // 动态布局规则，绑定事件
            this.getDynamicLayoutListDetail(res.data.layoutId);
            // this.isaddShow = true;
            this.loadingDetail = false;
          });
        })
        .catch(() => {
          this.loadingDetail = false;
        });
    },
    /**
     * 根据url回显表单值，有一些url回显在echoParams方法中处理了
     * @param {Object} attr:当前字段数据
     */
    getUrlVal(attr={}){
      let value=''
      if(attr.constructor===Object){
        // 获取路由参数
        let urlParam=this.$route.query||{}
        // 获取路由参数key数组
        let urlKeyAry=Object.keys(urlParam)
        // 路由参数中是否存在该字段
        // 特殊处理地址字段，直接进入
        if(urlKeyAry.includes(attr.apiname)||(attr.fieldType==='AD'&&Array.isArray(attr.children))){
          // 查找、主详字段还需要传key和value的值
          // key就是apiname，value是apiname_lkold
          if(urlKeyAry.includes(attr.apiname+'_lkold')){
            let option={value:urlParam[attr.apiname],label:urlParam[attr.apiname+'_lkold']}
            this.addFieldOption(attr.apiname,option)
          }
          // 地址字段
          // baseForm组件中filterFormData方法，v.fieldType == "AD"
          
          if(attr.fieldType==='AD'){
            /**
             * 地址字段说明：他是有5个子字段组成。如地址字段AP为  khdz.由如下字段组成。
              国家   khdz00
              省份/区  khdz01
              城市  khdz02
              街道  khdz04
              邮编  khdz05
             */
            if(Array.isArray(attr.children)){
              attr.children.forEach(itemAD=>{
                if(urlKeyAry.includes(itemAD.name)){
                  itemAD.value=urlParam[itemAD.name]
                }
              })
            }
          }else{
            // 非地址字段赋值
          // 处理特殊字符
          value=decodeURIComponent(urlParam[attr.apiname])
          }
          
        }

      }
      // 给字段设置默认值
       if(value){
        attr.value = value;
       }else if(attr.defaultValue && attr.defaultValue !== "") {
        attr.value = attr.defaultValue;
      }
      


    },
    /*
      根据路由中传入的参数给表单赋值，进行回显。参数最多三个。
      再新增加一个参数jumpTo确定新建成功后的操作：
        1.不传代表新建成功弹出新建成功提示后关闭；
        2.传detail代表新建成功后跳转数据详情；
        3.传list代表新建成功后跳对象列表。
     */
    echoParams() {
      // 先判断URL中是否带有参数
      if (location.href.split("?").length < 2) return;
      // 取到所有参数
      let params = location.href.split("?")[1].split("&");
      let paramsArr = [];
      // 将key=value转为数组-对象格式
      params.map((item) => {
        let object = item.split("=");
        paramsArr.push({
          apiname: decodeURIComponent(object[0]),
          value: decodeURIComponent(object[1]),
        });
      });
      // 判断是否带有jumpTo，存储为全局变量
      if (paramsArr.some((item) => item.apiname === "jumpTo")) {
        this.jumpTo = paramsArr.find((item) => item.apiname === "jumpTo").value;
      } else {
        this.jumpTo = "";
      }
      // 将默认的两个参数addType及prop、jumpTo过滤掉
      paramsArr = paramsArr.filter(
        (item) =>
          item.apiname !== "addType" &&
          item.apiname !== "prop" &&
          item.apiname !== "jumpTo"
      );
      // 将剩余的其他参数在formAttr中赋值进行回显
      for (let i = 0; i < paramsArr.length; i++) {
        for (let j = 0; j < this.formAttr.length; j++) {
          if (paramsArr[i].apiname === this.formAttr[j].apiname) {
            this.formAttr[j].value = paramsArr[i].value;
            continue;
          }
        }
      }
    },
    // 根据jumpTo确定新建成功后的操作
    saveAfter(id, objid) {
      if (!this.jumpTo) {
        // 三秒后关闭
        setTimeout(() => {
          window.close();
        }, 3000);
      } else if (this.jumpTo === "detail") {
        // 详情页
        this.$router.push({
          path: `/commonObjects/detail/${id}/DETAIL`,
        });
      } else if (this.jumpTo === "list") {
        // 对象列表页
        this.$router.push({
          path: `/commonObjects/views/table/${this.objectApi}/${objid}/${this.prefix}`,
        });
      }
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0]) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            this.isZhucongfield = false;
            this.getLookupValue(item, attr.value);
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 查找带值
    lookupValue(selectVal, selectItem, formData) {
      this.isZhucongfield = false;
      this.lookupValues.forEach((lookupValue) => {
        if (lookupValue.expression.split(",")[0] === selectItem.apiname) {
          this.getLookupValue(lookupValue, selectVal, formData);
        }
      });
    },
    // 查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      fieldJson.push({
        fieldrelateid: lookupRule.id,
        id: id,
        expression: lookupRule.expression,
        iscover: lookupRule.iscover,
      });
      let param = {
        objId: this.prefix,
        fieldJson: JSON.stringify(fieldJson),
      };
      if (this.isZhucongfield) {
        // 从对象前缀
        param.objId = this.relatedObjPrefix;
      }
      CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
        carryValue = res.data && res.data.length > 0 ? res.data[0].value : null;
        // 区分主从录入查找带值
        if (this.isZhucongfield) {
          this.$refs.EditableCell.forEach((editableCell) => {
            if (
              editableCell.lineIndex === this.rowIndex - 1 &&
              editableCell.fieldId === lookupRule.id
            ) {
              editableCell.editValue = carryValue;
            }
          });
        } else {
          // 主对象查找带值
          // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
          this.formAttr.forEach((attr) => {
            if (attr.fieldId === lookupRule.id) {
              // 新建时必须修改
              // 查找、查找多选字段特殊处理
              if (
                (attr.fieldType === "Y" || attr.fieldType === "MR") &&
                res.data[0].value &&
                res.data[0].lkid &&
                res.data[0].value !== "" &&
                res.data[0].lkid !== ""
              ) {
                this.optionList[attr.prop] = [
                  {
                    value: res.data[0].lkid,
                    label: res.data[0].value,
                  },
                ];
              }
              // formData !== undefined表示表单数据变化触发查找带值
              if (formData !== undefined) {
                // 查找、查找多选字段特殊处理
                if (
                  attr.fieldType === "Y" &&
                  res.data[0].lkid &&
                  res.data[0].lkid !== ""
                ) {
                  formData[attr.prop] = res.data[0].lkid;
                } else if (
                  attr.fieldType === "MR" &&
                  res.data[0].lkid &&
                  res.data[0].lkid !== ""
                ) {
                  formData[attr.prop] = [res.data[0].lkid];
                } else {
                  formData[attr.prop] = carryValue;
                }
              } else {
                if (
                  attr.fieldType === "Y" &&
                  res.data[0].lkid &&
                  res.data[0].lkid !== ""
                ) {
                  attr.value = res.data[0].lkid;
                  this.$refs.baseForm &&
                    this.$set(
                      this.$refs.baseForm.formData,
                      attr.apiname,
                      res.data[0].lkid
                    );
                } else if (
                  attr.fieldType === "MR" &&
                  res.data[0].lkid &&
                  res.data[0].lkid !== ""
                ) {
                  attr.value = [res.data[0].lkid];
                  this.$refs.baseForm &&
                    this.$set(this.$refs.baseForm.formData, attr.apiname, [
                      res.data[0].lkid,
                    ]);
                } else {
                  attr.value = carryValue;
                  this.$refs.baseForm &&
                    this.$set(
                      this.$refs.baseForm.formData,
                      attr.apiname,
                      carryValue
                    );
                }
              }
            }
          });
        }
      });
    },
    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }

      this.showSearchTable = false;
      this.formAttr.forEach((attr) => {
        if (attr.fieldId === fieldId) {
          this.$set(this.optionList, attr.prop, options);
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }
          this.$refs.baseForm.filterOptions = [];

          // 若有查找带值，将值带入
          this.getALLLookupValue(
            this.lookupValues,
            attr.apiname,
            optionValue,
            formData
          );
        }
      });
    },
    // 合并主从录入和主记录查找带值
    getALLLookupValue(lookupValueExpression, fieldName, id, formData) {
      let promiseList = [];

      // 查找字段值为空时不走带值规则
      if (id.toString() !== "") {
        for (let idx in lookupValueExpression) {
          let lookupRule = lookupValueExpression[idx];
          if (lookupRule.expression.split(",")[0].toLowerCase() === fieldName) {
            // id: 查找记录的id
            let fieldJson = [];

            fieldJson.push({
              fieldrelateid: lookupRule.id,
              id: id,
              expression: lookupRule.expression,
              iscover: lookupRule.iscover,
            });
            let param = {
              objId: this.prefix,
              fieldJson: JSON.stringify(fieldJson),
            };
            if (this.isZhucongfield) {
              // 从对象前缀
              param.objId = this.relatedObjPrefix;
            }

            promiseList.push(
              CommonObjApi.getLookupRelatedFieldValue(param)
                .then((res) => {
                  let result = {
                    res: res,
                    lookupRule: lookupRule,
                  };
                  return result;
                })
            );
          }
        }
        // 查找带值合并请求
        Promise.all(promiseList).then((allRes) => {
          allRes.forEach((result) => {
            let res = result.res;
            let lookupRule = result.lookupRule;
            let carryValue = "";
            carryValue =
              res.data && res.data.length > 0 ? res.data[0].value : null;
            // 区分主从录入查找带值
            if (this.isZhucongfield) {
              this.$refs.EditableCell.forEach((editableCell) => {
                if (
                  editableCell.lineIndex === this.rowIndex - 1 &&
                  editableCell.fieldId === lookupRule.id
                ) {
                  editableCell.editValue = carryValue;
                }
              });
            } else {
              // 主对象查找带值
              // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
              this.formAttr.forEach((attr) => {
                if (attr.fieldId === lookupRule.id) {
                  // 新建时必须修改
                  // 查找、查找多选、主详字段特殊处理
                  if (
                    (attr.fieldType === "Y" ||
                      attr.fieldType === "MR" ||
                      attr.fieldType === "M") &&
                    res.data[0].value &&
                    res.data[0].lkid &&
                    res.data[0].value !== "" &&
                    res.data[0].lkid !== ""
                  ) {
                    this.optionList[attr.prop] = [
                      {
                        value: res.data[0].lkid,
                        label: res.data[0].value,
                      },
                    ];
                  }
                  // formData !== undefined表示表单数据变化触发查找带值
                  if (formData !== undefined) {
                    // 查找、查找多选字段特殊处理
                    if (
                      (attr.fieldType === "Y" || attr.fieldType === "M") &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      formData[attr.prop] = res.data[0].lkid;
                    } else if (
                      attr.fieldType === "MR" &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      formData[attr.prop] = [res.data[0].lkid];
                    } else {
                      if (
                        (attr.fieldType === "P" ||
                          attr.fieldType === "N" ||
                          attr.fieldType === "c") &&
                        (carryValue === "" || carryValue === null)
                      ) {
                        formData[attr.prop] = undefined;
                      } else {
                        formData[attr.prop] = carryValue;
                      }
                    }
                  } else {
                    if (
                      (attr.fieldType === "Y" || attr.fieldType === "M") &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      attr.value = res.data[0].lkid;
                      this.$refs.baseForm &&
                        this.$set(
                          this.$refs.baseForm.formData,
                          attr.prop,
                          res.data[0].lkid
                        );
                    } else if (
                      attr.fieldType === "MR" &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      attr.value = [res.data[0].lkid];
                      this.$refs.baseForm &&
                        this.$set(this.$refs.baseForm.formData, attr.prop, [
                          res.data[0].lkid,
                        ]);
                    } else {
                      attr.value = carryValue;
                      this.$refs.baseForm &&
                        this.$set(
                          this.$refs.baseForm.formData,
                          attr.prop,
                          carryValue
                        );
                    }
                  }

                  // 带值字段如果有字段依赖、查找、动态布局规则，执行
                  let changeEvent =
                    attr.changeEvent !== undefined
                      ? attr.changeEvent.split(",")
                      : [];
                  changeEvent.forEach((eventItem) => {
                    if (eventItem === "control") {
                      this.control(
                        attr.value || this.$refs.baseForm.formData[attr.prop],
                        attr.fieldId,
                        this.$refs.baseForm.formData
                      );
                    } else if (eventItem === "lookupValue") {
                      this.lookupValue(
                        attr.value || this.$refs.baseForm.formData[attr.prop],
                        attr,
                        this.$refs.baseForm.formData
                      );
                    } else if (eventItem === "handleAction") {
                      this.handleAction();
                    }
                  });
                }
              });
            }
          });
        });
      }
    },
    // 依赖关系
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.baseForm.formData,
              true
            );
          }
        });
      });
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];

      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  controledFieldId.push(controlAttr.fieldId);
                  controledFieldProp.push(controlAttr.prop);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });

          // control.controlVals.forEach((vals) => {
          //   // 控制值
          //   vals.dependFields.forEach((dependField) => {
          //     this.formAttr.forEach((controlAttr, idx) => {
          //       if (controlAttr.fieldId === dependField.dependfieldid) {
          //         // 清空被控字段值并递归
          //         if (controlAttr.fieldType === 'Q') {
          //           this.$set(formData, controlAttr.apiname, [])
          //         } else {
          //           this.$set(formData, controlAttr.apiname, '')
          //         }
          //         this.control(
          //           formData[controlAttr.apiname],
          //           dependField.dependfieldid,
          //           formData
          //         )
          //       }
          //     })
          //   })
          // })
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }
      }
    },
    // 动态布局规则
    getDynamicLayoutListDetail(layoutId) {
      CommonObjApi.getDynamicLayoutListDetail({
        layoutId: layoutId,
      }).then((res) => {
        // 清空动态布局规则
        this.actionRequireds = [];
        this.actionReadonlys = [];
        this.actionShowFields = [];
        this.actionShowSections = [];
        this.actionConditions = res.data;

        res.data !== null &&
          res.data.forEach((action) => {
            // 重置规则控制字段
            action.mainConditions.forEach((mainCondition) => {
              // 主规则
              this.handleCondition(mainCondition, false);

              this.formAttr.forEach((attr, idx) => {
                // 地址和地理定位字段动态布局返回的是父字段id
                let fieldId = attr.fieldId;
                if (attr.parentType === "AD" || attr.parentType === "LT") {
                  fieldId = attr.parentId;
                }
                if (fieldId === mainCondition.fieldId) {
                  let changeEvent =
                    attr.changeEvent === undefined
                      ? "handleAction"
                      : `${attr.changeEvent},handleAction`;
                  this.$set(this.formAttr[idx], "changeEvent", changeEvent);
                }
              });

              // 二级规则
              mainCondition.dynamicPageConditions &&
                mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                  this.handleCondition(dynamicPage, false);
                  //
                  dynamicPage.twoLevelConditions.forEach(
                    (twoLevelCondition) => {
                      this.formAttr.forEach((attr, idx) => {
                        // 地址和地理定位字段动态布局返回的是父字段id
                        let fieldIdTwo = attr.fieldId;
                        if (
                          attr.parentType === "AD" ||
                          attr.parentType === "LT"
                        ) {
                          fieldIdTwo = attr.parentId;
                        }
                        if (fieldIdTwo === twoLevelCondition.fieldId) {
                          let changeEvent =
                            attr.changeEvent === undefined
                              ? "handleAction"
                              : `${attr.changeEvent},handleAction`;
                          this.$set(
                            this.formAttr[idx],
                            "changeEvent",
                            changeEvent
                          );
                        }
                      });
                    }
                  );
                });
            });
          });

        // 走规则
        this.handleAction();
      });
    },
    // 重置规则控制字段
    handleCondition(condition, flag) {
      // 设置字段/部分 是否显示/必填 注：部分下有必填字段时不隐藏该部分
      // 当flag为真时，规则中只要有一个条件满足，该字段显示/必填就为真
      // 每个控制字段变化时所有规则重新走一遍
      if (flag) {
        if (condition.action_requireds) {
          condition.action_requireds.forEach((requiredField) => {
            //  如果动态布局不是只读的，判断该字段动态布局是否是必填的，需要结合动态布局是否是必填的，如果是必填的，那么就是必填，只读为false。动态布局优先级永远是大于原布局
            this.actionRequireds.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.required = item.required || flag;
              }
            });
          });
        }
        if (condition.action_readonlys) {
          condition.action_readonlys.forEach((readonlysField) => {
            this.actionReadonlys.forEach((item) => {
              if (item.fieldId === readonlysField.field_id) {
                item.readonlys = item.readonlys || flag;
              }
            });
          });
        }
        if (condition.action_showFields) {
          condition.action_showFields.forEach((requiredField) => {
            this.actionShowFields.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.show = item.show || flag;
              }
            });
          });
        }
        if (condition.action_showSections) {
          condition.action_showSections.forEach((requiredField) => {
            this.actionShowSections.forEach((item) => {
              if (item.selectionId === requiredField.section_id) {
                item.showSection = item.showSection || flag;
              }
            });
          });
        }
      } else {
        // 初始化需要去重
        // 字段显示与否
        if (condition.action_showFields) {
          let showFields = [];
          condition.action_showFields.forEach((requiredField) => {
            if (!showFields.includes(requiredField.field_id)) {
              showFields.push(requiredField.field_id);
              this.actionShowFields.push({
                fieldId: requiredField.field_id,
                show: flag,
              });
            }
          });
        }
        // 部分显示与否
        if (condition.action_showSections) {
          let showSections = [];
          condition.action_showSections.forEach((requiredField) => {
            if (!showSections.includes(requiredField.section_id)) {
              showSections.push(requiredField.section_id);
              this.actionShowSections.push({
                sectionName: requiredField.sectionName,
                selectionId: requiredField.section_id,
                showSection: flag,
              });
            }
          });
        }
        // 字段必填与否
        if (condition.action_requireds) {
          let requireds = [];
          condition.action_requireds.forEach((requiredField) => {
            if (!requireds.includes(requiredField.field_id)) {
              requireds.push(requiredField.field_id);
              this.actionRequireds.push({
                fieldId: requiredField.field_id,
                required: flag,
              });
            }
          });
        }
        // 只读字段

        if (condition.action_readonlys) {
          let readonlys = [];
          condition.action_readonlys.forEach((readonlysField) => {
            if (!readonlys.includes(readonlysField.field_id)) {
              readonlys.push(readonlysField.field_id);
              this.actionReadonlys.push({
                fieldId: readonlysField.field_id,
                readonlys: flag,
              });
            }
          });
        }
      }
    },
    /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
    // 走规则
    handleAction() {
      // 清空动态布局规则
      this.actionRequireds = [];
      this.actionShowFields = [];
      this.actionShowSections = [];
      this.actionReadonlys = [];

      this.actionConditions.forEach((action) => {
        // 重置规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          // 主规则
          this.handleCondition(mainCondition, false);

          // 二级规则
          mainCondition.dynamicPageConditions &&
            mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
              this.handleCondition(dynamicPage, false);
            });
        });
      });

      this.actionConditions.forEach((action) => {
        // 循环规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          let judgment = this.judgValue(
            mainCondition.fieldName,
            mainCondition.operator,
            mainCondition.value,
            mainCondition.fieldType
          );
          if (judgment) {
            this.handleCondition(mainCondition, true);
            // 二级规则 - 符合一级规则时才走二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                // 二级条件执行
                let judgment = [];
                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  let judfment2= this.judgValue(
                      twoLevelCondition.fieldName,
                      twoLevelCondition.operator,
                      twoLevelCondition.value,
                      twoLevelCondition.fieldType
                    );
                    
                  judgment.push(judfment2)
                });
                // 执行高级条件
                if (this.isJudgment(judgment,dynamicPage.label)) {
                  this.handleCondition(dynamicPage, true);
                }
              });
          }
        });
      });

      // 根据规则设置字段显示/隐藏
      this.actionShowFields.forEach((actionShowField) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionShowField.fieldId) {
            if (actionShowField.show) {
              attr.hidden = false;
            } else {
              attr.hidden = true;
            }
          }
        });
      });

      // 设置部分显示/隐藏
      this.actionShowSections.forEach((actionShowSection) => {
        if (actionShowSection.sectionName) {
          this.formAttr.forEach((attr) => {
            // 控制某部分标题显示/隐藏
            // if (attr.title === actionShowSection.sectionName || attr.groupTitle == actionShowSection.sectionName) {
            if (attr.selectionId === actionShowSection.selectionId) {
              if (actionShowSection.showSection) {
                attr.hidden = false;
                attr.groupShow = true;
              } else {
                // 动态布局下只能设置条件符合时部分显示、字段显示、字段只读、字段必填
                // 如果动态规则条件不成立，原布局部分下有必填字段时不隐藏该部分
                let requiredFields = this.formAttr.find((item) => {
                  return (
                    item.selectionId === actionShowSection.selectionId &&
                    item.required
                  );
                });
                if (requiredFields !== undefined) {
                  attr.hidden = false;
                  attr.groupShow = true;
                } else {
                  attr.hidden = true;
                  attr.groupShow = false;
                }
              }
            }
          });
        }
      });

      // 必须先走字段必填，在走字段只读
      // 根据规则设置字段必填
      this.actionRequireds.forEach((actionRequire) => {
        // 需要判断该字段动态布局是否只读,并且只读条件成立，或者原布局该字段只读
        // 该字段动态布局是否只读,并且只读条件成立
        let flag = this.actionReadonlys.find((item) => {
          return item.fieldId === actionRequire.fieldId && item.readonlys;
        });
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionRequire.fieldId) {
            // 判断该字段动态布局或者原布局是否只读
            if (flag == undefined && attr.editCopy) {
              // 只读不成立
              // 判断动态必填或者原布局必填是否成立
              if (attr.requiredCopy || actionRequire.required) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur",
                    // 请输入
                  },
                ];
                // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段无规则
                // 清空必填，设置可编辑
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      //                 控制字段只读
      this.actionReadonlys.forEach((actionReadonly) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionReadonly.fieldId) {
            // 原字段只读或者动态规则只读条件成立
            if (!attr.editCopy || actionReadonly.readonlys) {
              attr.edit = false;
              attr.rules = [];
            } else {
              // 只读不成立，判断原布局或动态布局必填
              let flag = this.actionRequireds.find((item) => {
                return item.fieldId === actionReadonly.fieldId && item.required;
              });
              if (attr.requiredCopy || flag !== undefined) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur",
                    // 请输入
                  },
                ];
                // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段无规则
                // 清空必填，设置可编辑
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      //                 控制字段只读
      this.actionReadonlys.forEach((actionReadonly) => {
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === actionReadonly.fieldId) {
            // 原字段只读或者动态规则只读条件成立
            if (!attr.editCopy || actionReadonly.readonlys) {
              attr.edit = false;
              attr.rules = [];
            } else {
              // 只读不成立，判断原布局或动态布局必填
              let flag = this.actionRequireds.find((item) => {
                return item.fieldId === actionReadonly.fieldId && item.required;
              });
              if (attr.requiredCopy || flag !== undefined) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur", // 请输入
                  },
                ]; // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段没有规则
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      this.$refs.baseForm && this.$refs.baseForm.resetRules();
    },
    // 判断值
    judgValue(field, operator, value, fieldType) {
      //
      if (
        this.$refs.baseForm &&
        (this.$refs.baseForm.formData[field] || this.$refs.baseForm.formData[field] === 0)
      ) {
        if (fieldType === "Q") {
          //  以后组件值是数组的都要走这里
          //  多选列表选中的值
          let valueAry = this.$refs.baseForm.formData[field];
          // 主字段设置的数组
          let actionAry = value.split(",");
          // 多选列表选中值中被主字段设置的数据包含的值
          let filterAry = actionAry.filter((item) => {
            return valueAry.indexOf(item) > -1;
          });
          switch (operator) {
            case "e":
              // 等于(全包含，并且数组长度都一样)
              return (
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );
            case "n":
              // 不等于
              return !(
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );

            case "c":
              // 包含（只要有其中一个条件就成立）
              return filterAry.length > 0;
            case "k":
              // 不包含
              return filterAry.length === 0;
          }
        } else {
          // 判断字段类型
          fieldType = fieldType ? INPUTTYPE[fieldType] : fieldType;
          // 获取表单中该字段的值
          let formDateField = this.$refs.baseForm.formData[field].toString();

          if (fieldType === "date" || fieldType === "datetime") {
            // 处理日期、日期时间字段
            formDateField = new Date(formDateField).getTime().toString();
            value = new Date(value).getTime().toString();
          }

          if (fieldType === "time") {
            // 处理时间字段
            let formField = formDateField.split(":");
            let valueField = value.split(":");
            formDateField = (
              Number(formField[0] ? formField[0] : "") * 3600 +
              Number(formField[1] ? formField[1] : "") * 60 +
              Number(formField[2] ? formField[2] : "")
            ).toString();

            value = (
              Number(valueField[0] ? valueField[0] : "") * 3600 +
              Number(valueField[1] ? valueField[1] : "") * 60 +
              Number(valueField[2] ? valueField[2] : "")
            ).toString();
          }
          switch (operator) {
            case "e":
              // 等于
              return formDateField === value;
            case "n":
              // 不等于
              return formDateField !== value;
            case "l":
              // 小于
              return Number(formDateField) < Number(value);
            case "g":
              // 大于
              return Number(formDateField) > Number(value);
            case "m":
              // 小于或等于
              return Number(formDateField) <= Number(value);
            case "h":
              // 大于或等于
              return Number(formDateField) >= Number(value);
            case "c":
              // 包含
              return formDateField.includes(value);
            case "k":
              // 不包含
              return !formDateField.includes(value);
            case "s":
              // 起始字符
              return formDateField.indexOf(value) === 0;
          }
        }
      } else {
        // 如果是不包含、不等于返回true，其他false
        let flag = operator === "n" || operator === "k";
        // 如果value为空，此时operator是e（等于）返回为true
        if(operator === 'e' && !value && value!== 0){
          flag = true
        }
        return flag;
      }
    },
    // 表单添加属性
    addFormAttr(group, colNum, attr, isChildren, position, parentAttr) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      // 单独存储备用原布局中的required和edit,动态布局设置规则的时候使用
      attr.requiredCopy = attr.required;
      attr.editCopy = attr.edit;
      if (attr.required && attr.edit) {
        if (this.objectApi === "Contact" && this.contactes === "contactsNew") {
          if (attr.apiname === "loginname") {
            attr.rules = [
              {
                required: attr.required,
                message: `${this.$i18n.t(
                  "label_tabpage_placeenterz"
                )} ${this.$i18n.t("UG_Lead_005")}`,
                trigger: "blur",
                // 请输入
              },
            ];
          } else {
            attr.rules = [
              {
                required: attr.required,
                message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                  attr.fieldLabel
                }`,
                trigger: "blur",
                // 请输入
              },
            ];
          }
        } else {
          attr.rules = [
            {
              required: attr.required,
              message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                attr.fieldLabel
              }`,
              trigger: "blur",
              // 请输入
            },
          ];
        }
      }
      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (
        parentAttr &&
        (parentAttr.fieldType == "AD" || parentAttr.fieldType == "LT")
      ) {
        // 如果父字段是地址和地理定位字段，存储父字段fieldId
        attr.parentId = parentAttr.fieldId;
        attr.parentType = parentAttr.fieldType;
      }
      // 给字段设置默认值,如果url中存在传值，则使用传进来的值
      // if (attr.defaultValue && attr.defaultValue !== "") {
      //   // 
      //   attr.value = attr.defaultValue;
      // }
      // 根据url回显表单值,
      this.getUrlVal(attr)
       // 非查找/查找多选字段
      if (attr.fieldType !== "AD" && attr.fieldType !== "LT") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 评分+1
        if (attr.type === "score") {
          attr.value = Number(attr.value) - 1;
        }
        // 选项列表和选项列表多选
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          // 记录类型可改
          if (attr.prop === "recordtype") {
            // 新建时设置记录类型到表单中
            // this.$set(attr, "value", this.recordType);
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }

          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });

          this.optionList[attr.prop] = options;
          if (attr.type === "multi-select") {
            attr.value =
              // 无
              attr.value ===
                `--${this.$i18n.t("label.data.newtemplate.null")}--` ||
              attr.value === "" ||
              attr.value === null ||
              attr.value === undefined
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // 无
            attr.value =
              attr.value ===
                `--${this.$i18n.t("label.data.newtemplate.null")}--` ||
              attr.data[0].value === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            if (
              attr.value !== "" &&
              attr.data &&
              attr.data.length > 0 &&
              attr.data[0].value !== ""
            ) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            } else {
              attr.value = "";
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }

          // 新建时设置默认值和选项
          if (
            attr.defaultValue &&
            attr.defaultValue !== "" &&
            attr[`${attr.apiname}Name`] &&
            attr[`${attr.apiname}Name`] !== "" &&
            (attr.fieldType === "Y" || attr.fieldType === "M")
          ) {
            let option = [
              {
                label: attr[`${attr.apiname}Name`],
                value: attr.defaultValue,
              },
            ];
            this.$set(this.optionList, attr.prop, option);
          }
          attr.readonly = true;

          // 新增时默认 所有人 为当前登录用户
          if (attr.prop === "ownerid") {
            attr.value = this.currentUserInfo.id;
            let options = [
              {
                label: this.currentUserInfo.name,
                value: this.currentUserInfo.id,
              },
            ];
            this.$set(this.optionList, attr.prop, options);
          }
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        // colNum === 1 || attr.type === "kindeditor" ? "100%" : "50%";

        attr.groupTitle = group.title;
        attr.selectionId = group.selectionId;
        attr.groupShow = true;
        // 标签和新建时自动编号字段不展示，空位留出
        if (attr.apiname !== "cloudcctag" && !(attr.fieldType === "V")) {
          this.formAttr.push(attr);
        } else {
          this.formAttr.push({
            width: "50%",
            colNum: 2,
            attrIndex: this.attrIndex,
            type: "noData",
            hidden: false,
            groupShow: true,
          });
        }
      } else {
        // 地址和地理定位特殊处理
        attr.children.forEach((item) => {
          this.addFormAttr(group, colNum, item, true, position, attr);
          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (item["name"].slice(item["name"].length - 2) === "00") {
            this.getCountrySelectValue(item["name"]);
          }
        });
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
      if (attr.prop === "role") {
        let options = [];
        let obj = {};
        attr.roleList.forEach((item) => {
          obj = {
            label: item.rolename,
            value: item.roleid,
          };
          options.push(obj);
        });
        this.$set(this.optionList, attr.prop, options);
      }
      if (attr.prop === "profile") {
        let options = [];
        let obj = {};
        attr.profileList.forEach((item) => {
          obj = {
            label: item.profilename,
            value: item.id,
          };
          options.push(obj);
        });
        //
        this.$set(this.optionList, attr.prop, options);
      }
      // 如果为事件或任务类型，默认分配当前登录用户，特殊处理名称及相关项
      if (
        this.objId === "event" ||
        this.objId === "task" ||
        this.objectApi === "Event" ||
        this.objectApi === "Task"
      ) {
        // 如果操作为新建，填充部分默认值
        if (attr.prop === "belongtoid") {
          attr.fieldType = "MR";
          attr.type = "remote-multi-select";
          attr.value = [this.currentUserInfo.id];
          this.optionList.belongtoid = [
            {
              value: this.currentUserInfo.id,
              label: this.currentUserInfo.name,
            },
          ];
          this.getRelatedItem();
        } else if (attr.prop === "begintime") {
          attr.value = this.eventDate.start || this.getHour().start;
        } else if (attr.prop === "endtime") {
          attr.value = this.eventDate.end || this.getHour().end;
        } else if (attr.prop === "whoid") {
          if (this.objectApies !== "") {
            if (this.objectApies === "Lead" || this.objectApies === "cloudcclead" ||this.objectApies === "Contact") {
              if (this.bigTitle.length !== 0) {
                let child = {};
                let obj = {
                  whoname: this.bigTitle[0].fieldValue,
                  whoobj: this.objectNames,
                };
                child = obj;
                attr.child = child;
                attr.value = this.recordIds;
              }
            }
          }
          if (this.homeNweObjid !== "") {
            if (
              this.homeNweObjid === "lead" ||
              this.homeNweObjid === "contact"
            ) {
              if (this.homeNweName !== "") {
                let child = {};
                let obj = {
                  whoname: this.homeNweName,
                  whoobj: this.homeContactName,
                };
                child = obj;
                attr.child = child;
                attr.value = this.homeNweId;
              }
            }
          }
        } else if (attr.prop === "relateid") {
          if (this.objectApies !== "") {
            if (this.objectApies !== "Lead"  &&this.objectApies !== "cloudcclead"  && this.objectApies !== "Contact") {
              if (this.bigTitle.length !== 0) {
                let child = {};
                let obj = {
                  relatename: this.bigTitle[0].fieldValue,
                  relateobj: this.objectNames,
                };
                child = obj;
                attr.child = child;
                attr.value = this.recordIds;
              }
            }
          }
          if (this.homeNweObjid !== "") {
            if (
              this.homeNweObjid !== "lead" &&
              this.homeNweObjid !== "contact"
            ) {
              if (this.homeNweName !== "") {
                let child = {};
                let obj = {
                  relatename: this.homeNweName,
                  relateobj: this.homeContactName,
                };
                child = obj;
                attr.child = child;
                attr.value = this.homeNweId;
              }
            }
          }
        }
        // } else {
        //   if (attr.prop === "belongtoid") {
        //     attr.fieldType = "MR";
        //     attr.type = "remote-multi-select";
        //     attr.value = [attr.value];
        //     this.getRelatedItem();
        //   }
        // }
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codevalue,
              key: item.codekey,
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    remoteSearch(item) {
      this.fieldId = item.fieldId;
      this.showSearchTable = true;
    },
    cancel() {
      window.close();
    },
    //关闭无法审批弹窗
    closeApproval() {
      this.saveAfter(
        localStorage.getItem("relevantObjectDataId"),
        localStorage.getItem("relevantObjId")
      ); // 指定新增成功之后的操作
    },
    //提交待审批
    submitApproval() {
      this.isApproval = true;
      this.save();
    },
    //提交待审批后如何跳转
    approvalSuccess() {
      this.saveAfter(
        localStorage.getItem("relevantObjectDataId"),
        localStorage.getItem("relevantObjId")
      ); // 指定新增成功之后的操作
    },
    save(val) {
      this.$refs.baseForm.$refs.elForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let dataList = [];
          let data = JSON.parse(JSON.stringify(this.$refs.baseForm.formData));
          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined) {
                if (data[key]) {
                  data[key] = data[key].toString();
                } else {
                  data[key] = "";
                }
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          if (
            !data["recordtype"] &&
            this.recordType !== null &&
            this.recordType !== "null"
          ) {
            data["recordtype"] = this.recordType;
          }
          dataList.push(data);
          if (this.objectApi == "CloudccKArticle") {
            dataList[0].new_article_type = "create";
          }
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };
          CommonObjApi.save(params)
            .then((res) => {
              if (
                res.data &&
                res.data[0] &&
                res.data[0].isSaveSuccess === "true"
              ) {
                if (
                  this.$route.query.addType &&
                  this.$route.query.addType === "option"
                ) {
                  localStorage.setItem("addRelevantObjectType", "option");
                  localStorage.setItem("relevantObjectDataId", res.data[0].id);
                  localStorage.setItem("relevantObjId", res.data[0].objid);
                  localStorage.setItem(
                    "relevantObjectDataName",
                    res.data[0].name
                  );
                  localStorage.setItem(
                    "relevantObjectApi",
                    this.$route.query.prop
                  );
                } else {
                  localStorage.setItem(
                    "relevantObjectApi",
                    this.$route.params.objectApi
                  );
                  localStorage.setItem(
                    "relevantObjectLevel",
                    Number(this.$route.params.relevantObjectLevel) + 1
                  );
                }
                this.$message.success(this.$i18n.t("label.search.saveok"));
                if (val && val == "approval") {
                  //提交待审批
                  Approval.submitApproval({ relatedId: res.data[0].id }).then(
                    (params) => {
                      // 提交待审批成功
                      if (params.result) {
                        this.$message({
                          message: this.$i18n.t(
                            "label.webtocloudcc.submit.success"
                          ),
                          type: "success",
                        });
                        this.saveAfter(res.data[0].id, res.data[0].objid); // 指定新增成功之后的操作
                      } else {
                        if (params.returnCode == "Manual") {
                          // TODO 弹窗填写审批信息
                          this.$refs.nextApprovalref.IsShowdialog(
                            true,
                            res.data[0].id
                          );
                        } else if (params.returnCode !== "Manual") {
                          this.errInfo = params.returnInfo;
                          this.failedDialog = true;
                        }
                      }
                    }
                  );
                } else {
                  this.saveAfter(res.data[0].id, res.data[0].objid); // 指定新增成功之后的操作
                }
              } else {
                this.$message.error(
                  res.data[0].errormessage || this.$i18n.t("chatter.save.f")
                );
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  watch: {
    recordType(newVal,oldVal) {
      if(newVal!=oldVal&&newVal){
        this.getFormAttr();
      }
      
    },
    // 监听表单下拉值数据变化，防止url主详、查找字段下拉数据赋值不成功
    optionList(nval){
     
      if(nval.constructor===Object){
        let keyAry=Object.keys(nval)
        // 获取路由参数
        let urlParam=this.$route.query||{}
        // 获取路由参数key数组
        let urlKeyAry=Object.keys(urlParam)
        urlKeyAry.forEach(item=>{
          if(keyAry.includes(item)){
            // 当前项在url中有传参
            if(urlKeyAry.includes(item+'_lkold')){
              // 需要判断该项是否已经存在，如果不存在，添加到下拉项中
              if(Array.isArray(nval[item])){
                // 是否存在该项
                let ishas=nval[item].find(nvalItem=>nvalItem.value===urlParam[item])
                if(!ishas){
                  nval[item].push({value:urlParam[item],label:urlParam[item+'_lkold']})
                }
              }else{
                nval[item]=[{value:urlParam[item],label:urlParam[item+'_lkold']}]
              }
        }
          }
        })
      }
      
    }
  },
};
</script>

<style scoped lang="scss">
.dialog-form {
  padding: 30px;
  .baseForm{
    margin-top: -30px;
    ::v-deep .el-form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }
    ::v-deep .el-form-item__label {
      width: 100% !important;
      padding-left: 12px;
    }
    ::v-deep .el-form-item__content {
      padding-left: 12px !important;
      margin-left: 0 !important;
      width: 90%;
      // 会造成下拉多选错位
      // line-height: 32px;
    }
    ::v-deep .el-select {
      padding-left: 0px !important;
    }
    // 验证时不展示错误内容
    ::v-deep .el-form-item__error {
      left: 2%;
    }
    ::v-deep .groupTitle {
      margin: 12px 0px 0px 0px;
      // height: 36px;
      line-height: 36px;
      font-size: 14px;
      padding-left: 12px;
      background: #f3f7ff;
      color: #080707;
      font-weight: bold;
      border-radius: 4px;
      font {
        display:contents
      }
    }
  }
   
}
.bottom_butts {
  text-align: right;
  position: fixed;
  bottom: 10px;
  left: 30px;
  right: 30px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.7);
}
</style>
