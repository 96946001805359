import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get('/user/getUserInfo', data)
  } else {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    })
  }
}

// 获取视图列表表头
export function getViewListHeader(data) {
  return axios.post('/view/list/getViewListHeader', data)
}

// 获取视图列表记录
export function getViewListData(data) {
  return axios.post('/view/list/getViewListAjax', data)
}

// 获取视图列表页面信息
export function getViewListRecently(data) {
  return axios.post('/view/list/getViewListRecently', data)
}

// 获取视图详细信息
export function getViewInfo(data) {
  return axios.post('/view/getViewInfo', data)
}

// 获取视图字段设置
export function getFieldSetup(data) {
  return axios.post('/view/getFieldSetup', data)
}

// 保存视图字段设置
export function saveFieldSetup(data) {
  return axios.post('/view/saveFieldSetup', data)
}

// 获取记录类型
export function getRecordType(data) {
  return axios.post('/batch/getRecordType', data)
}

// 获取批量新增布局信息
export function getBatchAdding(data) {
  return axios.post('/batch/getBatchInfoToAdd', data)
}

// 获取对象详细信息
export function getDetail(data) {
  return axios.post('/objectdetail/getDetail', data)
}

// 更新视图列表记录
export function updateViewListAjax(data) {
  return axios.post('/view/list/updateViewListAjax', data)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}

// 获取查找数据
export function getLookupData(data) {
  return axios.post('/lookup/getLookupData', data)
}
// 获取小程序、网站设置个案报修单所有人列表
export function getUserList(data) {
  return axios.post('/case/getUserList', data)
}
// 查找带值
export function getLookupRelatedFieldValue(data) {
  return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 添加或更新对象记录
export function save(data) {
  return axios.post('/objectInfo/save', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
  return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}

// 删除记录
export function del(data) {
  return axios.post('/objectdetail/delete', data)
}

// 获取批量删除的数据
export function batchDelete(data) {
  return axios.post('/batch/batchDelete', data)
}

// 获取需要合并的数据
export function merge(data) {
  return axios.post('/duplication/getDataByIds', data)
}

// 主从录入表头
export function getMasterInfo(data) {
  return axios.post('/master/getMasterInfo', data)
}

// 主从录入保存
export function saveMaster(data) {
  return axios.post('/master/saveMaster', data)
}

//获取更改所有人页面信息-单条数据
export function getChangeOwner(data) {
  return axios.post('/transfer/getChangeOwner', data)
}

//获取更改所有人页面信息-批量
export function getTransfer(data) {
  return axios.post('/transfer/getTransfer', data)
}
//获取文件大小限制
export function queryLimit(data) {
  return axios.post('/file/queryLimit', data)
}

//上传文件
export function upload(data, config) {
  return axios.post('/file/upload', data, config)
}

//上传图片文件
export function uploadImg(data) {
  return axios.post('/file/uploadImg', data)
}

//查询帖子
export function getCCChats(data) {
  return axios.post('/ccchat/microPost/getCCChats', data)
}
// 文件预览
export function viewFile(data) {
  return axios.post('file/preview', data)
}
//@用户列表
export function queryAtUsers(data) {
  return axios.post('/ccchat/microPost/queryAtUsers', data)
}
// 发表文件帖子
export function addMicroPostD(data) {
  return axios.post('/ccchat/microPost/addMicroPostD', data)
}
//投票帖子
export function changePolls(data) {
  return axios.post('/ccchat/microPost/changePolls', data)
}
// 收藏帖子
export function favoriteFeed(data) {
  return axios.post('/ccchat/microPost/favoriteFeed', data)
}
//点赞帖子
export function praiseFeed(data) {
  return axios.post('/ccchat/microPost/praiseFeed', data)
}

// 追随或取消追随,=关注，取消关注
export function setFollow(data) {
  return axios.post('/ccchat/person/setFollow', data)
}
// 是否追随或取消追随,=是否关注，取消关注
export function isFollow(data) {
  return axios.post('/ccchat/person/isFollow', data)
}

//发布帖子评论或回复
export function addMicroPostComments(data) {
  return axios.post('/ccchat/microPost/addMicroPostComments', data)
}
//  分享帖子 
export function shareMicroPost(data) {
  return axios.post('/ccchat/microPost/shareMicroPost', data)
}
//删除评论
export function removeMicroComment(data) {
  return axios.post('/ccchat/microPost/removeMicroComment', data)
}

//评论帖子 喜欢（点赞）留言或评论
export function praiseComment(data) {
  return axios.post('/ccchat/microPost/praiseComment', data)
}
//发布普通帖子
export function addMicroPost(data) {
  return axios.post('/ccchat/microPost/addMicroPost', data)
}

//获取CCChat后台设置信息
export function getSetupInfo() {
  return axios.post('/ccchat/person/getSetupInfo')
}

//单条数据转移所有人
export function updateChangeOwner(data) {
  return axios.post('/transfer/updateChangeOwner', data)
}

//批量转移所有人
export function updateTransfer(data) {
  return axios.post('/transfer/updateTransfer', data)
}

//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}

// 获取市场活动添加成员数据
export function getAddMemberData(data) {
  return axios.post('/campaign/getAddMemberData', data)
}

// 获取市场活动成员状态选项列表值(公海池中 ButtonWrapper组件中 退回，废弃操作也用到此API)
// 获取选项列表值
export function getSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

// 添加市场活动成员
export function addMember(data) {
  return axios.post('/campaign/addMember', data)
}

//产品详情页上价格手册条目查询
export function queryProductPricebookItems(data) {
  return axios.post('/pricebook/queryProductPricebookItems', data)
}

//产品详情页上添加标准价格
export function addStdPricebookItems(data) {
  return axios.post('/pricebook/addStdPricebookItems', data)
}

//产品详情页上添加价格手册
export function addCustomPricebookItems(data) {
  return axios.post('/pricebook/addCustomPricebookItems', data)
}

//保存价格手册
export function saveProductPricebookItem(data) {
  return axios.post('/pricebook/saveProductPricebookItem', data)
}

//删除价目表记录
export function deleteItem(data) {
  return axios.post('pricebook/deleteItem', data)
}

//产品详情页编辑价目表
export function editPricebookItem(data) {
  return axios.post('pricebook/editPricebookItem', data)
}

//是否隐藏添加价格手册按钮
export function hideAddPricebookButton(data) {
  return axios.post('pricebook/hideAddPricebookButton', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
  return axios.post('activity/getQuickActivityPage', data)
}

// 获取对象权限
export function getObjectPermission(data) {
  return axios.post('objectdetail/getObjectPermission', data)
}

// 更新列表字段宽度
export function updateViewFieldMemory(data) {
  return axios.post('view/list/updateViewFieldMemory', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
  return axios.post('objectdetail/queryMoreDataByName', data)
}

// 潜在客户热度设置信息
export function getLeadHeat(data) {
  return axios.post('leadscore/getLeadHeat', data)
}

// 获取用户界面设置
export function getUserSetup(data) {
  return axios.get('/user/getUserSetup', data)
}

// 队列
export function queryQueue(data) {
  return axios.post('/objectdetail/queryQueue', data)
}

// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
  return axios.post('/objectdetail/checkQueueAndPartner', data)
}

// 解除记录锁定状态
export function recordUnLocked(data) {
  return axios.post('/objectdetail/recordUnLocked', data)
}

//富文本图片上传
export function fileCKUploadImg(data) {
  return axios.post('/file/CKUploadImg', data)
}

//获取对象下公海池
export function selMarketsea(data) {
  return axios.post('/marketseaFunction/selMarketsea', data)
}

// 退回与回收（公海池）
export function marketseaReturnAndRecovery(data) {
  return axios.post('/marketseaFunction/marketseaReturnAndRecovery', data)
}

// 自定义按钮
export function openCall(data) {
  return axios.post('/openCall/common', data)
}
// 贺文娟添加的营销渠道二维码相关的接口
// 传营销渠道id，获取每个步骤的数据，去回显
export function stepOne(data) {
  return axios.post('/channel/step1', data)
}
export function stepTwo(data) {
  return axios.post('/channel/step2', data)
}
export function stepThree(data) {
  return axios.post('/channel/step3', data)
}
export function stepFour(data) {
  return axios.post('/channel/step3', data)
}
export function stepFive(data) {
  return axios.post('/channel/step5', data)
}
export function stepSix(data) {
  return axios.post('/channel/step6', data)
}
export function stepSeven(data) {
  return axios.post('/channel/step7', data)
}
// 营销渠道注册页面预览
export function channelPreview(data) {
  return axios.post('/channel/preview', data)
}
// 营销渠道保存
export function channelSave(data) {
  return axios.post('/channel/save', data)
}
//获取营销渠道列表
export function getMarketList(data) {
  return axios.post('/channel/list', data)
}

//删除营销渠道
export function delMarketList(data) {
  return axios.post('/channel/delete', data)
}

//批量删除营销渠道
export function delMoreMarketList(data) {
  return axios.post('/channel/batchdelete', data)
}

//市场营销渠道二维码
export function getQrCode(data) {
  return axios.post('/channel/qrcode', data)
}

//根据营销渠道查询活动成员
export function getMarketingActivities(data) {
  return axios.post('/channel/queryMemberByChannel', data)
}

//发送邀约码
export function sendQrCode(data) {
  return axios.post('/channel/sendQrCode', data)
}

//天眼查是否启用设置
export function isOpen(data) {
  return axios.post('/tianyancha/isOpen', data)
}

//新建事件前检查重复
export function checkEventConflict(data) {
  return axios.post('/objectInfo/checkEventConflict', data)
}

// 日程预约 初始化
export function initAllSelectOptions(data) {
  return axios.post('/appointment/initAllSelectOptions', data)
}

// 保存日程预约
export function appointmentSave(data) {
  return axios.post('appointment/save', data)
}

// 邮件查找关联日程预约
export function queryAppointments(data) {
  return axios.post('/appointment/queryAppointments', data)
}

// 保存海报信息
export function savePoster(data) {
  return axios.post('/poster/savePoster', data)
}

// 获取海报信息
export function getPosterInfo(data) {
  return axios.post('/poster/getPosterInfo', data)
}

// 获取form的列表信息
export function getPosterFormList(data) {
  return axios.post('/poster/getPosterFormList', data)
}

// 删除海报
export function deletePoster(data) {
  return axios.post('/poster/deletePoster', data)
}

// 获取员工邀约的列表
export function getEmployeeInvitedList(data) {
  return axios.post('/employeeinvited/getEmployeeInvitedList', data)
}

// 获取海报的列表
export function getPosterList(data) {
  return axios.post('/poster/getPosterList', data)
}


// 获取海报信息
export function getEmployeeInvitedInfo(data) {
  return axios.post('/employeeinvited/getEmployeeInvitedInfo', data)
}

// 保存员工邀约
export function saveEmployeeInvited(data) {
  return axios.post('/employeeinvited/saveEmployeeInvited', data)
}


// 删除员工邀约
export function deleteEmployeeInvited(data) {
  return axios.post('/employeeinvited/deleteEmployeeInvited', data)
}


// 查询项目模板
export function queryProjectTemplateList(data) {
  return axios.post('/projectTemplate/queryProjectTemplateList', data)
}

// 项目模板保存接口
export function templateSave(data) {
  return axios.post('/projectTemplateOperation/templateSave', data)
}

// 标记/取消标记为模板
export function modifyProjectTemplate(data) {
  return axios.post('/projectTemplate/modifyProjectTemplate', data)
}

// 主从录入保存税种
export function taxSave(data) {
  return axios.post('/tax/save', data)
}
// 主从录入获取税种信息
export function ListTax(data) {
  return axios.post('/tax/listTax', data)
}

// 获取产品关联的税
export function listRelatedTax(data) {
  return axios.post('/tax/listRelatedTax', data)
}
// 查询所选产品关联的价格手册条目价格
export function relatedProductPrice(data) {
  return axios.post('/master/relatedProductPrice', data)
}
// 删除税
export function deleteTax(data) {
  return axios.post('/tax/deleteTax', data)
}
// 删除税
export function masterRecordDetailInfo(data) {
  return axios.post('/master/masterRecordDetailInfo', data)
}